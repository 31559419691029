body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-hover-blue:hover {
  color:  #3399ff;
}

.custom-hover-red:hover {
  color: #b30000;
}

.custom-hover-strong:hover {
  font-weight: bold;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page-to-print {
  width: 21cm;
  min-height: 29.7cm;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page-to-print {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    pointer-events: none;
  }
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), 
  url(./fonts/Montserrat-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), 
  url(./fonts/Montserrat-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), 
  url(./fonts/Montserrat-Regular.otf) format('opentype');
}
